import styles from "./home.scss";
import owl_styles from "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import icon_facebook from "../../assets/icons/1_Facebook.svg";
import icon_instagram from "../../assets/icons/2_Instagram.svg";
import icon_instagram_red from "../../assets/icons/2_Instagram_rojo.svg";
import icon_youtube from "../../assets/icons/4_Youtube.svg";
import icon_twitter from "../../assets/icons/3_Twitter.svg";
import check_icon from "../../assets/icons/1_check2.png";

import arrow_right from "../../assets/icons/1B_Vermasflecha.svg";

import sponsors from "../sponsors";
import styles_coffee from "../coffee.main.scss";

function render(page_template_content, base_url, id_season) {
  page_template_content.innerHTML = "";
  let content = document.createElement("div");

  //widget area
  displayWidget(true);

  //news
  content.appendChild(displayNews(base_url));

  //banner area
  // let div_banner_container = document.createElement("div");
  // div_banner_container.id = "img_banner_test";
  // content.appendChild(div_banner_container);
  // div_banner_container.appendChild(displayTopBanner(base_url, 29));
  content.appendChild(displayTopBanner(base_url, 29));

  //teams
  content.appendChild(displayTeams(base_url, id_season));

  //score tables (standings) with banner
  let div_element = document.createElement("div");
  content.appendChild(div_element);
  showBannerOrTable(div_element, base_url, id_season);

  // content.appendChild(displayStandings(base_url, id_season));

  //news LNBP contigo
  content.appendChild(displayLNBPContigo(base_url));

  //videos
  content.appendChild(displayVideos(base_url));

  //small banner
  content.appendChild(displayTopBanner(base_url, 32));

  //lideres or banner
  content.appendChild(displayLideres(base_url, id_season));

  //social network
  content.appendChild(displaySocialNetwork(base_url));

  //sponsors
  sponsors.displaySponsors(content);

  page_template_content.appendChild(content);
}

function showBannerOrTable(container, base_url, id_season) {
  var ajax_data = {
    id_section: 11,
    id_device: 0,
    count_banners: 1,
    url_link: ""
  };

  $.ajax({
    type: "POST",
    url: base_url + "/analytics/banners/view",
    data: ajax_data,
    async: false
  }).done(function (response) {
    if (
      response.items.length > 0 &&
      response.items[0].item.show_position_table == 1
    ) {
      //show banner
      let div = document.createElement("div");
      div.classList.add("text-center");
      let img = document.createElement("img");
      img.style.width = "100%";
      img.src = base_url + response.items[0].item.full_url_image;

      div.appendChild(img);
      container.appendChild(div);
    } else {
      //show table
      container.appendChild(displayStandings(base_url, id_season));
    }
  });
}

function displayWidget(display = false) {
  if (display) {
    $("#js__widget_plugin").show();
  } else {
    $("#js__widget_plugin").hide();
  }
}

function createBannerForNews(item_response, base_url) {
  let url = "news.html?news_id=" + item_response.id;

  let a = document.createElement("a");
  a.href = url;
  a.classList.add(styles.banner);
  a.classList.add("item");
  a.style.backgroundImage = `url(${base_url + item_response.full_url_image})`;
  a.title = item_response.title;
  //a.text = "test"; //item_response.text;

  let caption_wrapper = document.createElement("div");
  caption_wrapper.classList.add(styles.caption_wrapper);

  a.appendChild(caption_wrapper);

  let caption = document.createElement("div");
  caption.classList.add(styles.caption);

  caption_wrapper.appendChild(caption);

  let title = document.createElement("div");
  caption.appendChild(title);
  title.innerHTML = item_response.title;

  let excerpt = document.createElement("div");
  caption.appendChild(excerpt);

  return a;
}

function displayNews(base_url) {
  let primary_banner = document.createElement("div");
  primary_banner.id = "primary_banner";
  primary_banner.classList.add("owl-carousel");
  primary_banner.classList.add("owl-theme");
  primary_banner.classList.add(styles.primary_banner);

  let secondary_banner = document.createElement("div");
  secondary_banner.id = "secondary_banner";
  secondary_banner.classList.add(styles.secondary_banner);

  let banner_row = document.createElement("div");
  banner_row.classList.add("row", styles.banner_row);
  let banner_col_1 = document.createElement("div");
  banner_col_1.classList.add(
    "col-lg-8",
    "col-md-8",
    "col-sm-12",
    styles.margin_bottom_1
  );
  let banner_col_2 = document.createElement("div");
  banner_col_2.classList.add(
    "col-lg-4",
    "col-md-4",
    "col-sm-12",
    styles.margin_bottom_1
  );
  banner_row.appendChild(banner_col_1);
  banner_row.appendChild(banner_col_2);

  banner_col_1.appendChild(primary_banner);
  banner_col_2.appendChild(secondary_banner);

  //Prepare the request data.
  var ajax_data = {
    count: 5
  };

  $.ajax({
    type: "POST",
    url: base_url + "/communication/news/all",
    data: ajax_data
  }).done(function (response) {
    response.items.forEach(function (item, i) {
      if (i < 3) {
        primary_banner.appendChild(createBannerForNews(item, base_url));
      } else {
        secondary_banner.appendChild(createBannerForNews(item, base_url));
      }
    });

    $("#primary_banner").owlCarousel({
      items: 1,
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 2000,
      autoplayHoverPause: true
    });
  });

  return banner_row;
}

/* Banner */
function displayTopBanner(base_url, id_entry) {
  //Prepare the request data.
  var ajax_data = {
    id: id_entry
  };

  let image_container = document.createElement("div");
  image_container.classList.add("text-center", styles_coffee.banner_center);

  $.ajax({
    type: "POST",
    url: base_url + "/cms/entries/single",
    data: ajax_data
  }).done(function (response) {
    let image = document.createElement("img");
    image.src = base_url + response.item.full_url_image;

    if (
      response.item.full_url_image != null &&
      !response.item.full_url_image.includes("no-image.jpg")
    ) {
      image_container.appendChild(image);
    }
  });

  return image_container;
}

//this methos is used by displayTeams until de cms is fix and updated
//aug 18 2021
function searchTeamConference(nameKey, myArray) {
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i].id === nameKey) {
      return myArray[i].pivot.id_conference;
    }
  }
}

function displayTeams(base_url, id_season) {
  let container = document.createElement("div");
  container.id = "js__home_teams_list";
  createSectionTitle("Equipos LNBP", container);

  let teams = document.createElement("div");
  container.appendChild(teams);

  teams.classList.add(styles.teams_banner);

  //Prepare the request data.
  var ajax_data = {
    id_season: id_season
  };

  $.ajax({
    type: "POST",
    url: base_url + "/sports/seasons/participants/latest",
    data: ajax_data
  }).done(function (response) {
    response.items_team.forEach(function (item) {
      let $conference = searchTeamConference(
        item.id_entity,
        response.season.participants
      );
      let team = document.createElement("a");
      team.href = item.contact.website;
      team.src = item.contact.website;

      let team_img = document.createElement("img");
      team_img.src = base_url + item.full_url_logo;

      team.appendChild(team_img);
      teams.appendChild(team);
    });
  });

  return container;
}

function displayBanner(base_url, id_section) {
  let banner_link = document.createElement("a");
  banner_link.classList.add(styles.banner_standings_table);
  let banner_img = document.createElement("img");
  banner_link.appendChild(banner_img);

  //Prepare the request data.
  var ajax_data = {
    id_section,
    count_banners: 1,
    id_device: 0,
    url_link: window.location.href.split("/").pop()
  };

  $.ajax({
    type: "POST",
    url: base_url + "/analytics/banners/view",
    data: ajax_data
  }).done(function (response) {
    if (response.items.length > 0) {
      //TODO: track banner viws and clicks from array second item item_2
      var img = response.items[0].item;
      banner_img.src = base_url + img.full_url_image;
      banner_link.href = img.url_link;

      // selector.attr("src", base_url + item.full_url_image);
      // selector.css("cursor", "pointer");
      // selector.show();
      // selector.on("click", function() {
      //     cs_track_banner_click(item_2.id);
      //     cs_redirect(item.url_link);
      // });
    } else {
      selector.hide();
    }
  });

  return banner_link;
}

function displayStandings(base_url, id_season) {
  //Prepare the request data.

  //table standings //
  let container = document.createElement("div");
  createSectionTitle(
    "Tabla de Posiciones",
    container,
    true,
    "/position_table.html"
  );

  container.classList.add(styles.standings_container, "row");

  let col_1 = document.createElement("div");
  let col_2 = document.createElement("div");

  col_1.classList.add("col-sm-12", "col-md-8", "col-lg-8", "text-center");
  col_2.classList.add("col-sm-12", "col-md-4", "col-lg-4", "text-center");

  col_2.appendChild(displayBanner(base_url, 3));

  container.appendChild(col_1);
  container.appendChild(col_2);

  let table = document.createElement("table");
  table.classList.add(styles.standing_table, "table");
  let table_head = document.createElement("thead");
  let table_tr = document.createElement("tr");
  let table_body = document.createElement("tbody");

  ["#", "", "EQUIPO", "JJ", "JG", "JP", "PTS"].forEach((item) => {
    let table_th = document.createElement("th");
    table_th.innerText = item;
    table_tr.appendChild(table_th);
  });

  table.appendChild(table_head);
  table.appendChild(table_body);
  table_head.appendChild(table_tr);

  col_1.appendChild(table);
  //table standings //

  var ajax_data = {
    id_season: id_season
  };
  $.ajax({
    type: "POST",
    url: base_url + "/sports/standings/season",
    data: ajax_data
  }).done(function (response) {
    if (response.items_standing.length > 0) {
      let count = 1;

      response.items_standing.forEach(function (item) {
        let id_conference = 0;

        response.item_season.participants.forEach((item_participant) => {
          if (item_participant.id === item.team.id_entity) {
            if (item_participant.pivot.id_conference !== null) {
              id_conference = item_participant.pivot.id_conference;
            }
            return;
          }
        });

        let table_body_row = document.createElement("tr");
        table_body_row.classList.add(styles.standing_table_row);
        let team_logo = document.createElement("img");
        team_logo.src = base_url + item.team.full_url_logo;
        [
          count,
          team_logo,
          item.team.name,
          item.games,
          item.games_won,
          item.games_lost,
          item.points
        ].forEach((item) => {
          let table_cell = document.createElement("td");

          item instanceof HTMLElement
            ? table_cell.appendChild(item)
            : (table_cell.innerHTML = item);
          table_body_row.appendChild(table_cell);
        });

        table_body.appendChild(table_body_row);
        count++;
        console.log(count);

        //TODO: display table with conference title, dividing each team by conference
        // let conference = document.createElement("div");
        // selector_conference.find("#tbl_standings tbody").append(
        //   template_standings_item
        //     .replace(/\|PLACE\|/g, item.place === "0" ? "N/A" : item.place)
        //     .replace(/\|LOGO\|/g, base_url + item.team.full_url_logo)
        //     .replace(/\|TEAM\|/g, item.team.name)
        //     .replace(/\|GAMES\|/g, item.games)
        //     .replace(/\|GAMESLOST\|/g, item.games_lost)
        //     .replace(/\|GAMESWON\|/g, item.games_won)
        //     .replace(/\|POINTS\|/g, item.points)
        //     .replace(/\|CLASS\|/g, item.games === 0 ? "no_games" : "")
        // );
        //table standings //
      });
    } else {
      $("#tbl_standings").append(
        "<tr><td colspan='4'>No se han registrado puntuaciones.</td></tr>"
      );
    }
  });

  return container;
}

function displayNewsType1(news, div_element, base_url) {
  let container = document.createElement("div");
  container.addEventListener(
    "click",
    (e) => {
      e.preventDefault();
      window.location.href = "news.html?news_id=" + news.id;
      // "news.html?news_id=3125"
    },
    true
  );

  let img = document.createElement("img");
  img.src = base_url + news.full_url_image;

  let title = document.createElement("span");
  title.innerHTML = news.name;

  container.appendChild(img);
  container.appendChild(title);

  div_element.appendChild(container);
}

function displayNewsType2(news, div_element, base_url) {
  let container = document.createElement("div");
  container.style.paddingBottom = "1rem";
  container.addEventListener(
    "click",
    (e) => {
      e.preventDefault();
      window.location.href = "news.html?news_id=" + news.id;
      // "news.html?news_id=3125"
    },
    true
  );

  let img = document.createElement("img");
  img.src = base_url + news.full_url_image;

  let title = document.createElement("span");
  title.innerHTML = news.name.slice(0, 12) + "...";
  title.classList.add(styles.news_type_2_title);

  let line = document.createElement("hr");

  let description = document.createElement("span");
  description.textContent =
    news.description
      .replaceAll("<br>", " ")
      .replaceAll("<p>", " ")
      .replaceAll("<i>", " ")
      .replaceAll("</br>", " ")
      .replaceAll("</p>", " ")
      .replaceAll("</i>", " ")
      .slice(0, 50) + "...";

  let date = document.createElement("span");
  date.innerHTML = news.created_at.slice(0, 10);
  date.classList.add(styles.news_type_2_date);

  container.appendChild(img);
  container.appendChild(title);
  container.appendChild(line);
  container.appendChild(description);
  container.appendChild(date);

  div_element.appendChild(container);
}

function displayLNBPContigo(base_url) {
  let container = document.createElement("div");

  createSectionTitle("LNBP Contigo", container, true, "news.html");

  let row_1 = document.createElement("div");
  row_1.classList.add("row");

  let row_1_col_1 = document.createElement("div");
  row_1_col_1.classList.add(
    "col-sm-12",
    "col-md-6",
    "col-lg-6",
    styles.news_type_1
  );

  let row_1_col_2 = document.createElement("div");
  row_1_col_2.classList.add(
    "col-sm-6",
    "col-md-3",
    "col-lg-3",
    styles.news_type_2
  );

  let row_1_col_3 = document.createElement("div");
  row_1_col_3.classList.add(
    "col-sm-6",
    "col-md-3",
    "col-lg-3",
    styles.news_type_2
  );

  row_1.appendChild(row_1_col_1);
  row_1.appendChild(row_1_col_2);
  row_1.appendChild(row_1_col_3);

  let row_2 = document.createElement("div");
  row_2.classList.add("row");

  let row_2_col_1 = document.createElement("div");
  row_2_col_1.classList.add(
    "col-sm-12",
    "col-md-3",
    "col-lg-3",
    styles.news_type_2
  );

  let row_2_col_2 = document.createElement("div");
  row_2_col_2.classList.add(
    "col-sm-12",
    "col-md-3",
    "col-lg-3",
    styles.news_type_2
  );

  let row_2_col_3 = document.createElement("div");
  row_2_col_3.classList.add(
    "col-sm-12",
    "col-md-6",
    "col-lg-6",
    styles.news_type_1
  );

  row_2.appendChild(row_2_col_1);
  row_2.appendChild(row_2_col_2);
  row_2.appendChild(row_2_col_3);

  container.appendChild(row_1);
  container.appendChild(row_2);

  var ajax_data = {
    count: 6
  };
  $.ajax({
    type: "POST",
    url: base_url + "/communication/news/all",
    data: ajax_data
  }).done(function (response) {
    if (response.items[0]) {
      displayNewsType1(response.items[0], row_1_col_1, base_url);
    }
    if (response.items[1]) {
      displayNewsType2(response.items[1], row_1_col_2, base_url);
    }
    if (response.items[2]) {
      displayNewsType2(response.items[2], row_1_col_3, base_url);
    }
    if (response.items[3]) {
      displayNewsType2(response.items[3], row_2_col_1, base_url);
    }
    if (response.items[4]) {
      displayNewsType2(response.items[4], row_2_col_2, base_url);
    }
    if (response.items[5]) {
      displayNewsType1(response.items[5], row_2_col_3, base_url);
    }
  });

  return container;
}

function createSectionTitle(
  title_text,
  container,
  more_link = false,
  more_link_url = ""
) {
  let title_container = document.createElement("div");
  title_container.classList.add(styles.title_container);
  let title_check = document.createElement("img");
  title_check.src = check_icon;
  title_check.classList.add(styles.title_icon);

  let title = document.createElement("span");
  title.innerHTML = title_text;

  title_container.appendChild(title_check);
  title_container.appendChild(title);

  container.appendChild(title_container);

  if (more_link) {
    let more_link_anchor = document.createElement("a");
    more_link_anchor.href = more_link_url;
    more_link_anchor.classList.add(styles.more_link_anchor);

    let more_link_text = document.createElement("span");
    more_link_text.innerHTML = "Ver más";
    more_link_anchor.appendChild(more_link_text);

    let more_link_icon = document.createElement("img");
    more_link_icon.src = arrow_right;
    more_link_anchor.appendChild(more_link_icon);

    title_container.appendChild(more_link_anchor);
  }
}

function displayVideoCard(video, div_element, base_url) {
  let img_url = document.createElement("a");
  img_url.href = video.url;

  let img = document.createElement("img");
  img.src = base_url + video.full_url_thumbnail;

  let title = document.createElement("span");
  title.innerHTML = video.name.slice(0, 30) + "...";
  title.classList.add(styles.video_card_title);

  let line = document.createElement("hr");

  let description = document.createElement("span");
  description.textContent =
    video.description.replaceAll("<br>", " ").slice(0, 60) + "...";

  let date = document.createElement("span");
  date.innerHTML = video.created_at.slice(0, 10);
  date.classList.add(styles.video_card_date);

  img_url.appendChild(img);
  img_url.appendChild(title);
  img_url.appendChild(line);
  img_url.appendChild(description);
  img_url.appendChild(date);

  div_element.appendChild(img_url);
}

function displayVideos(base_url) {
  let videos_container = document.createElement("div");
  createSectionTitle("Últimos videos", videos_container, true, "/live.html");

  let row_1 = document.createElement("div");
  row_1.classList.add("row");

  videos_container.appendChild(row_1);

  let row_1_col_1 = document.createElement("div");
  row_1_col_1.classList.add(
    "col-sm-12",
    "col-md-3",
    "col-lg-3",
    styles.news_video_card
  );

  let row_1_col_2 = document.createElement("div");
  row_1_col_2.classList.add(
    "col-sm-12",
    "col-md-3",
    "col-lg-3",
    styles.news_video_card
  );

  let row_1_col_3 = document.createElement("div");
  row_1_col_3.classList.add(
    "col-sm-12",
    "col-md-3",
    "col-lg-3",
    styles.news_video_card
  );

  let row_1_col_4 = document.createElement("div");
  row_1_col_4.classList.add(
    "col-sm-12",
    "col-md-3",
    "col-lg-3",
    styles.news_video_card
  );

  row_1.appendChild(row_1_col_1);
  row_1.appendChild(row_1_col_2);
  row_1.appendChild(row_1_col_3);
  row_1.appendChild(row_1_col_4);

  //Prepare the request data.
  let ajax_data = {
    count: 4
  };

  $.ajax({
    type: "POST",
    url: base_url + "/media/videos/filtered",
    data: ajax_data
  }).done(function (response) {
    if (response.items[0]) {
      displayVideoCard(response.items[0], row_1_col_1, base_url);
    }
    if (response.items[1]) {
      displayVideoCard(response.items[1], row_1_col_2, base_url);
    }
    if (response.items[2]) {
      displayVideoCard(response.items[2], row_1_col_3, base_url);
    }
    if (response.items[3]) {
      displayVideoCard(response.items[3], row_1_col_4, base_url);
    }
  });

  return videos_container;
}

function displayLideresCard(scores, container, id_season, base_url) {
  //card
  let card = document.createElement("div");
  card.classList.add("row");

  //for each type(puntos, rebotes, asistencias) -> for each player

  scores.items.forEach((score) => {
    let card_row_col = document.createElement("div");
    card_row_col.classList.add("col-sm-12", "col-md-6", "col-lg-6");

    //card_title
    let card_title = document.createElement("span");
    card_title.innerHTML = score.name;
    card_row_col.appendChild(card_title);

    //card_item
    let card_item = document.createElement("div");
    card_item.classList.add("row", styles.card_item);

    var ajax_data = {
      id_category: score.id,
      id_season
    };

    let first_player = true;

    $.ajax({
      type: "POST",
      url: base_url + "/sports/seasons/rankings/category",
      data: ajax_data,
      async: false
    }).done(function (players) {
      // if there are no player hide the col
      if (players.items.length === 0) {
        card_row_col.style.display = "none";
      }

      //team player with team
      players.items.forEach((player) => {
        ////card_item->row->col 2
        let card_item_row_col_1 = document.createElement("div");
        card_item_row_col_1.classList.add(
          "col-xs-2",
          "col-sm-2",
          "col-md-2",
          "col-lg-2"
        );

        let team_img = document.createElement("img");
        team_img.src = base_url + player.team.full_url_logo;
        team_img.classList.add(styles.card_item_team_img);

        //insert team
        card_item_row_col_1.appendChild(team_img);

        ////card_item->row->col 5
        let card_item_row_col_2 = document.createElement("div");
        card_item_row_col_2.classList.add(
          "col-xs-5",
          "col-sm-5",
          "col-md-5",
          "col-lg-5"
        );
        card_item_row_col_2.classList.add(
          "col-xs-5",
          "col-sm-5",
          "col-md-5",
          "col-lg-5",
          styles.card_item_row_col_2
        );

        //insert name and position
        let player_name = document.createElement("span");
        player_name.innerHTML = player.player.name;
        player_name.classList.add(styles.card_item_player_data);

        let player_position = document.createElement("span");
        player_position.innerHTML = player.player.position;
        player_position.classList.add(styles.card_item_player_data);

        card_item_row_col_2.appendChild(player_name);
        card_item_row_col_2.appendChild(player_position);

        card_item.appendChild(card_item_row_col_1);
        card_item.appendChild(card_item_row_col_2);

        if (first_player) {
          first_player = false;
          let card_item_row_col_3 = document.createElement("div");
          card_item_row_col_3.classList.add(
            "col-xs-2",
            "col-sm-2",
            "col-md-2",
            "col-lg-2",
            styles.card_item_player_data_points_container
          );

          let player_points = document.createElement("span");
          player_points.innerHTML = player.points;
          player_points.classList.add(
            styles.card_item_player_data_points_points
          );

          card_item_row_col_3.appendChild(player_points);

          let card_item_row_col_4 = document.createElement("div");
          card_item_row_col_4.classList.add(
            "col-xs-3",
            "col-sm-3",
            "col-md-3",
            "col-lg-3",
            styles.card_item_player_img_container
          );

          let player_img = document.createElement("img");
          player_img.src = base_url + player.player.full_url_image;
          player_img.classList.add(styles.card_item_player_img);

          card_item_row_col_4.appendChild(player_img);

          //add the black background
          card_item_row_col_1.classList.add(styles.card_item_first_player);
          card_item_row_col_2.classList.add(styles.card_item_first_player);
          card_item_row_col_3.classList.add(styles.card_item_first_player);
          card_item_row_col_4.classList.add(styles.card_item_first_player);

          card_item.appendChild(card_item_row_col_3);
          card_item.appendChild(card_item_row_col_4);
        }
        //else
        ////card_item->row->col 5
        else {
          let card_item_row_col_3 = document.createElement("div");
          card_item_row_col_3.classList.add(
            "col-xs-5",
            "col-sm-5",
            "col-md-5",
            "col-lg-5"
          );

          let player_points = document.createElement("span");
          player_points.innerHTML = player.points;
          player_points.classList.add(styles.card_item_player_data_points);

          card_item_row_col_3.appendChild(player_points);

          card_item.appendChild(card_item_row_col_3);
        }
      });

      card_row_col.appendChild(card_item);
    });
    card.appendChild(card_row_col);
  });
  container.appendChild(card);
}

function displayLideres(base_url, id_season) {
  let container = document.createElement("div");

  createSectionTitle(
    "Líderes individuales",
    container,
    true,
    "/position_table.html"
  );

  //Prepare the request data.
  var ajax_data = {
    id_category: 25
  };

  $.ajax({
    type: "POST",
    url: base_url + "/catalog/categories/system",
    data: ajax_data,
    async: false
  }).done(function (response) {
    displayLideresCard(response, container, id_season, base_url);
  });

  return container;
}

function displaySocialNetwork() {
  let container = document.createElement("div");
  createSectionTitle("Redes Sociales", container);

  let social_networks = document.createElement("div");
  container.appendChild(social_networks);

  social_networks.classList.add(styles.social_network_banner);

  let social_networks_available = [
    {
      name: "facebook",
      url: "https://www.facebook.com/LNBPoficial/",
      icon: icon_facebook
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/lnbpoficial/",
      icon: icon_instagram_red
    },
    {
      name: "twitter",
      url: "https://twitter.com/LNBPoficial",
      icon: icon_twitter
    },
    {
      name: "youtube",
      url: "https://www.youtube.com/@LNBPOFICIAL",
      icon: icon_youtube
    }
  ];

  social_networks_available.forEach(function (social_network) {
    let anchor = document.createElement("a");
    anchor.href = social_network.url;

    let icon = document.createElement("img");
    icon.src = social_network.icon;

    anchor.appendChild(icon);
    social_networks.appendChild(anchor);
  });

  return container;
}

const methods = {
  render: (page_template_content, base_url, id_season) => {
    render(page_template_content, base_url, id_season);
  },
  displayWidget: (display) => {
    displayWidget(display);
  }
};

export default methods;
