import 'jquery';
import _ from "lodash";
import "./styles.scss";
import 'bootstrap';
import 'popper.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import template_styles from './layouts/page-template.scss';
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import page_template from './layouts/page-template'; 
import page_header from './components/header/header';
import page_home from './components/home/home';
import page_footer from './components/footer/footer';

const page_template_content = document.getElementById("js__page_template_content");
import coffee_main from "./components/coffee.main";
const base_url = coffee_main.base_url;
const id_season = coffee_main.id_season;

//set page template and loader
page_template.setPageTemplate();

//size menu items on sidebar
page_header.setPageTemplate();

//page content
page_home.render(page_template_content, base_url, id_season);

//page_footer
page_footer.render(base_url,id_season);

//hide loader at the end, always at the bottom of this index file
$(window).on("load", function() {
    page_template.hideLoader();
});

function cs_track_banner_view(id_section, selector) {
  if(selector.length > 0) {
      //Prepare the request data.
      var ajax_data = {
          id_section: id_section,
          id_device:  cs_get_id_device(),
          count_banners: 1,
          url_link:  window.location.href.split('/').pop()
      };

      $.ajax({
          type: "POST",
          url: base_url + "/analytics/banners/view",
          data: ajax_data
      })
      .done(function(response) {
          if(response.items.length > 0) {
              var item = response.items[0].item;
              var item_2 = response.items[0].item_2;

              selector.attr("src", base_url + item.full_url_image);
              selector.css("cursor", "pointer");
              selector.show();
              selector.on("click", function() {
                  cs_track_banner_click(item_2.id);
                  cs_redirect(item.url_link);
              });
          } else {
              selector.hide();
          }
      });
  }
}
